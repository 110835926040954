@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.marketingStrategiesSection {
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0);
  position: relative;
  padding: 2rem 0;
}

.marketingStrategiesSection:before {
  content: '';
  position: absolute;
  background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.03;
}

.marketingStrategiesSection-header {
  padding: 1rem;
}

.marketingStrategiesSection-header h1 {
  font-family: "Gabriola";
  color: #804e05d3;
  font-size: 2rem;
  line-height: 1.5rem;
  padding: .5rem 0;
  text-align: center;
}

.marketingStrategiesSection-content {
  padding: 1rem;
}

.marketingStrategiesSection-flex {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0;
}

.marketingStrategiesSection-left {
  width: 50%;
  margin: auto;
}

.marketingStrategiesSection-left ul li {
  padding: .2rem 0;
}

.marketingStrategiesSection-left h1 {
  font-family: "Gabriola";
  color: #804e05d3;
  font-size: 1.5rem;
  line-height: 1rem;
  padding: .5rem 0;
  text-align: left;
}

.marketingStrategiesSection-right {
  width: 500px;
  margin: auto;
  -webkit-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
  -moz-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
  box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
}

.marketingStrategiesSection-right img {
  width: 100%;
  border-radius: 10px;
}


.marketingStrategiesSection-footer {
  padding: 1rem;
}

.marketingStrategiesSection-footer h1 {
  font-family: "Gabriola";
  color: #804e05d3;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: .5rem 0;
  text-align: left;
}

.marketingStrategiesSection-footer p {
  padding: 1rem 0;
  font-size: 14px;
  font-style: italic;
  text-align: center;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

  .marketingStrategiesSection-flex {
    flex-wrap: wrap;
  }
  
  .marketingStrategiesSection-left {
    width: 100%;
    margin: auto;
  }
  
  .marketingStrategiesSection-right {
    width: 100%;
    margin: auto;
  }
  
  .marketingStrategiesSection-header p {
    text-align: center;
  }

  }


