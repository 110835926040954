@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.grantWritingSection {
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0);
  position: relative;
  padding: 2rem 0;
}

.grantWritingSection:before {
  content: '';
  position: absolute;
  background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.03;
}

.grantWritingSection-header {
  padding: 1rem;
}

.grantWritingSection-header h1 {
  font-family: "Gabriola";
  color: #804e05d3;
  font-size: 2rem;
  line-height: 1.5rem;
  padding: .5rem 0;
  text-align: center;
}

.grantWritingSection-header p {
  font-size: 14px;
}

.grantWritingSection-content {
  padding: 1rem;
}

.grantWritingSection-flex {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0;
}

.grantWritingSection-left {
  width: 50%;
  margin: auto;
}

.grantWritingSection-left ul li {
  padding: .2rem 0;
}

.grantWritingSection-left h1 {
  font-family: "Gabriola";
  color: #804e05d3;
  font-size: 1.5rem;
  line-height: 1rem;
  padding: .5rem 0;
  text-align: left;
}

.grantWritingSection-left p {
  font-size: 14px;
}

.grantWritingSection-right {
  width: 500px;
  margin: auto;
  -webkit-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
  -moz-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
  box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
}

.grantWritingSection-right img {
  width: 100%;
  border-radius: 10px;
}


.grantWritingSection-footer {
  padding: 1rem;
}

.grantWritingSection-footer h1 {
  font-family: "Gabriola";
  color: #804e05d3;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: .5rem 0;
  text-align: left;
}

.grantWritingSection-footer p {
  font-size: 14px;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

  .grantWritingSection-flex {
    flex-wrap: wrap;
  }
  
  .grantWritingSection-left {
    width: 100%;
    margin: auto;
  }
  
  .grantWritingSection-right {
    width: 100%;
    margin: auto;
  }
  
  .grantWritingSection-header p {
    text-align: center;
  }

  }


