.aboutSection {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 3rem 0;
  }
  
  .aboutSection:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.07;
  }

.aboutSectionCaption {
    width: 100%;
    padding: 1rem;
    text-align: center;
    margin: auto;
}

.aboutSectionCaption h1 {
    font-size: 1rem;
    text-align: left;
    text-transform: uppercase;
    color: #141413;
    font-family: "roboto";
}

.aboutSectionCaption p {
    padding: 1rem 0;
    text-align: justify;
}

.flexLeftItem p {
    text-align: left;
}




