.missionSection {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .missionSection:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.07;
  }

.missionSectionCaption {
    width: 50%;
    padding: 1rem;
    text-align: center;
    margin: auto;
}

.missionSectionCaption h1 {
    font-size: 3rem;
    text-align: center;
    color: #edaa09;
    font-family: "Gabriola";
}

.missionSectionCaption p {
    padding: 1rem 0;
    text-align: justify;
}


.missionSectionContent {
    display: flex;
    justify-content: center;
}

.flexLeftItem p {
    text-align: left;
}

.flexRight {
    width: 50%;
    padding: 2rem;
    margin: auto;
}

.flexRight img {
    width: 100%;
}


@media screen and (max-width: 940px) {
    .missionSectionContent {
        flex-wrap: wrap;
    }

    .missionSectionCaption {
        width: 100%;
    }
    
    .flexRight {
        width: 100%;
    }
    
    .flexRight img {
        width: 100%;
    }
}


