@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.contact-hero {
    width: 100%;
    height: 80dvh;
    position: relative;
    background: #12121379;
  }
  
  .contact-hero:before {
    content: '';
    position: absolute;
    background: url('../../assets/menu-bg-contact.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  
.contact-hero .contact-hero-content {
    position: absolute;
    width: 100%;
    bottom: 10%;
    padding: 1rem;
}

.contact-hero h1 {
    font-size: 5rem;
    line-height: 2rem;
    font-family: 'Gabriola', sans-serif;
    color: rgb(223, 218, 218);
    text-align: center;
}

.contact-hero h2 {
    font-size: 1rem;
    color: rgb(243, 236, 236);
    font-weight: normal;
    text-align: center;
    padding: .5rem 0;
}

.contact-hero p {
    font-size: 2rem;
    line-height: 2rem;
    font-family: 'Gabriola', sans-serif;
    color: rgb(223, 218, 218);
    text-align: center;
}

.contact-hero span {
    color: white;
}

/* MEDIA QUERIES */



@media screen and (max-width:940px) {

.contact-hero .contact-hero-content {
    position: absolute;
    width: 100%;
    top: 60%;
    left: 0%;
    padding: 1rem;
}

.contact-hero h1 {
    text-align: center;
}

.contact-hero h2 {
    text-align: center;
}

.contact-hero p {
    text-align: center;
}

}


@media screen and (max-width:700px) {

    .contact-hero {
        height: 100dvh;
      }

    }