@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.WhatWeDo-bg {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .WhatWeDo-bg:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.07;
  }


.wrapper-content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
}

.wrapper-content-left {
    width: 400px;
    text-align: center;
    margin: auto;
}

.wrapper-content-left img  {
    width: 100%;
}

.wrapper-content-right {
    width: 45%;
    margin: auto;
}

.wrapper-content-right h1 {
    text-transform: uppercase;
    color: #804e05d3;
    font-size: 1rem;
    letter-spacing: 5px; 
}

.wrapper-content-right p {
    margin: auto;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
}


.leaf {
    position: absolute;
    width: 15%;
    left: 1%;
    bottom: 0%;
}



@media screen and (max-width:940px)  {

    .wrapper-content {
        flex-wrap: wrap;
    }

    .wrapper-content-left {
        width: 80%;
    }

    .wrapper-content-right {
        width: 100%;
    }

    .wrapper-content-right h1, .wrapper-content-right h2{
        text-align: center;
    }

    .wrapper-content-right h2 {
        font-size: 1.5rem;
    }

    .leaf {
        display: none;
    }


}

