@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.insuranceContracting {
    width: 100%;
    height: 70vh;
    background: #00000054;
    position: relative;
  }
  
  .insuranceContracting:before {
    content: '';
    position: absolute;
    background: url('../../assets/market_research_hero_bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.insuranceContracting {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.insuranceContracting .insuranceContracting-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}

.insuranceContracting h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: rgba(255, 255, 255, 0.801);
    text-transform: uppercase;
    text-align: center;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.insuranceContracting h1 {
    font-size: 2rem;
}
}