.marketing {
    width: 100%;
    height: 100%;
    background: #24242500;
    position: relative;
  }
  
  .marketing:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.07;
  }


  .marketing-content {
    padding: 3rem 1rem;
    height: auto;
  }

.marketing-header {
    width: 100%;
    text-align: center;
}

.marketing-header h1 {
  color: #000000;
  font-size: 1.2rem;
  letter-spacing: 3px; 
  text-transform: uppercase;
}

.marketing-header p {
  padding: 1rem;
}

.marketing-flex {
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 0;
  gap: 1rem;
  justify-content: center;
}


.marketing-flexItem {
  width: 370px;
  height: auto;
  border: 1px solid rgba(107, 62, 150, 0.158);
  padding: 0 0 2rem 0;
  margin: 1rem;
}

.marketing-flexItem .flex-img {
  width: 100%;
}

.marketing-flexItem .flex-img img {
  width: 100%;
}

.marketing-flexItem .flex-caption h1{
  color: #804e05d3;
  padding: 1rem 0;
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
}


.marketing-flexItem .flex-caption p{
  text-align: center;
}

.marketing-btn {
  margin: auto;
  text-align: center;
  padding: 1rem;
}

.marketing-btn button {
  padding: .8rem 2rem;
  font-size: 1rem;
  border-radius: 10px;
  background-color: #804e05d3;
  border: none;
}

.marketing-btn button:hover {
  padding: .8rem 2rem;
  font-size: 1rem;
  border-radius: 10px;
  background-color: #5e3903d3;
  border: none;
}