@import url('https://fonts.googleapis.com/css2?family=Playfair&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');



.hero {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.hero .hero-container {
  position: absolute;
  text-align: center;
  width: 100%;
  background-color:#0000007e;
  height: 100%;
}

.hero-container .content {
  position: absolute;
  bottom: 10%;
  padding: 1rem;
  text-align: center;
  width: 100%;
}

.hero .content h1 {
  font-size: 7rem;
  text-align: center;
  color: #e4d8d8;
  font-family: "Gabriola";
  line-height: 5.5rem;
}


.hero h2 {
  font-size: 1.2rem;
  color: #e4d8d8;
  font-weight: normal;
}

.hero-btn a {
font-size: 4rem;
color: #ffffff;
font-family: "Gabriola";
}

.hero-btn span {
color: white;
font-size: 1.8rem;
}

.hero .hero-btn button {
  cursor: pointer;
  background-color: white;
  color: var(--primary-dark);
  border: none;
  font-weight: 300;
  padding: .5rem 2rem;
}


.fling-minislide { 
  display:block; 
  width:100%; 
  height:100vh; 
  overflow:hidden; 
  position:relative; 
  background-color:#000000;
}

.fling-minislide img { 
  position:absolute; 
  animation:fling-minislide 32s infinite, zoom-in 32s infinite; 
  opacity:0;  
  object-fit:cover; 
  width:100vw; 
  height:100%;
} 
  
  /**/

@keyframes fling-minislide {28%{opacity:1;} 32%{opacity:0;}}
.fling-minislide img:nth-child(4){animation-delay:0s;}
.fling-minislide img:nth-child(3){animation-delay:8s;}
.fling-minislide img:nth-child(2){animation-delay:16s;}
.fling-minislide img:nth-child(1){animation-delay:24s;}

@keyframes zoom-in{0%{transform:scale(1) translateY(0);transform-origin:50% 84%}100%{transform:scale(1.5) translateY(15px);transform-origin:center}}

.title-wrapper {
  background-color: rgba(0, 0, 0, 0.404);
}


/* MEDIA QUERIES */



@media screen and (max-width:940px) {


  .fling-minislide { 
    height:100dvh; 
  }




}


@media screen and (max-width:700px) {




  .hero .content h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
  
  .hero .content h2 {
    font-size: 1rem;
    padding: .5rem 0;
  }

  .hero-btn a {
    font-size: 2.5rem;
    color: #ffffff;
    font-family: "Gabriola";
    }
  


}


