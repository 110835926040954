.servicesSection-bg {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 2rem 0;
  }
  
  .servicesSection-bg:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.08;
  }

.servicesSectionCaption {
    width: 50%;
    padding: 1rem;
    text-align: center;
    margin: auto;
}

.servicesSectionCaption h1 {
    font-size: 1.5rem;
    text-align: left;
    color: #edaa09;
    font-family: "Gabriola";
    line-height: 1.5rem;
}

.servicesSectionCaption p {
    padding: .5rem 0;
    text-align: justify;
}


.servicesSectionContent {
    display: flex;
    justify-content: center;
}

.flexLeftItem p {
    text-align: left;
    font-size: 14px;
}

.flexImage {
    width: 50%;
    padding: 2rem;
    margin: auto;
}

.flexImage img {
    width: 100%;
    border-radius: .5rem;
}

.serviceSectionFooter {
    padding: 1rem;
}

.serviceSectionFooter p {
    padding: .5rem 0;
    text-align: justify;
}

.servicesSectionHeader {
    padding: 1rem;
}
.servicesSectionHeader h1 {
    font-size: 2rem;
    text-align: center;
    color: #edaa09;
    font-family: "Gabriola";
    line-height: 1.5rem;
}

.servicesSectionHeader p {
    padding: .5rem 0;
    text-align: justify;
    font-size: 14px;

}

@media screen and (max-width: 940px) {
    .servicesSectionContent {
        flex-wrap: wrap;
    }

    .servicesFlexReverse {
        flex-wrap: wrap-reverse;
    }

    .servicesSectionCaption {
        width: 100%;
    }
    
    .flexImage {
        width: 100%;
    }
    
    .flexImage img {
        width: 100%;
    }
}


@media screen and (max-width: 500px) {
    .servicesSectionCaption h1 {
        font-size: 1.2rem;
        line-height: 1.3rem;
    }

    .servicesSectionCaption2 h1 {
        font-size: 1.2rem;
        text-align: center;
        line-height: 1.3rem;
    }
    

}

