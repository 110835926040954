@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.trainingStaffSection-container {
    width: 100%;
    height: auto;
    position: relative;
  }
  
  .trainingStaffSection-container:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.03;
  }


.wrapper-content {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.trainingStaffSectionHeader {
    padding: 1rem;
}

.trainingStaffSectionHeader h1 {
    font-size: 2rem;
    text-align: center;
    color: #edaa09;
    font-family: "Gabriola";
    line-height: 1.5rem;
    padding: 1rem 0;
}

.trainingStaffSection-left {
    width: 50%;
    text-align: center;
    margin: auto;
}

.trainingStaffSection-left img {
    width: 100%;
    border-radius: 10px;
}

.trainingStaffSection-right {
    width: 50%;
    margin: auto;
}

.trainingStaffSection-right ul {
    padding: .5rem 0;
}

.trainingStaffSection-right h1 {
    font-size: 1rem;
    text-align: left;
    color: #edaa09;
    line-height: 1.5rem;
    text-transform: uppercase;
}

.trainingStaffSection-right h2 {
    font-size: 1.5rem;
    text-align: left;
    color: #804e05d3;
    font-family: "Gabriola";
    line-height: .5rem;
    padding: .5rem 0;
}


.trainingStaffSection-right p {
    text-align: justify;
    font-size: 14px;
}



@media screen and (max-width:940px) {
    .trainingStaffSection-left {
        width: 100%;
    }

    .wrap-reverse {
        flex-wrap: wrap-reverse;
    }

    .trainingStaffSection-right {
        width: 100%;
    }

}

