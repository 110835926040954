@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Poppins:wght@300&display=swap');


.mission-home {
    width: 100%;
    height: 70vh;

    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #16161679;
  }
  
  .mission-home:before {
    content: '';
    position: absolute;
    background: url('../../assets/mission-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-attachment: fixed;
  }

  .mission-home .mission-home-content
  {
    position: absolute;
    height: auto;
    width: 60%;
    left: 5%;
    bottom: 30%;
  }

.mission-home .mission-home-content h1 {
    font-family: 'Gabriola', sans-serif;
    text-align: center;
    color: white;
    padding: 2rem;
    font-size: 3rem;
}

.mission-home .mission-home-content p {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    color: rgb(255, 255, 255);
    padding: 1rem;
}


@media screen and (max-width:940px) {


  .mission-home .mission-home-content
  {
    position: absolute;
    height: auto;
    width: 100%;
    left: 0%;
    bottom: 25%;
    padding: 1rem;
  }

    .mission-home .mission-home-content h1 {

        font-size: 2rem;
    }


    }
